import { useStaticQuery, graphql } from 'gatsby'
import { useMemo } from 'react'

export const useAssets = () => {
  const data = useStaticQuery(graphql`
    query {
      allAssetsBaseJson {
        nodes {
          address
          addresses {
            current
            previous
          }
          airdropBip44
          assetId
          assetIndex
          assetType
          baseAssetName
          baseDenom
          chainBadgeColors
          contractGasLimit
          decimals
          displayName
          displayNetworkName
          displayNetworkTicker
          displayTicker
          feeAssetName
          gasLimit
          gradientColors
          gradientCoords {
            x1
            x2
            y1
            y2
          }
          info {
            description
            medium
            discord
            reddit
            telegram
            twitter
            website
          }
          keyDerivationAlgorithm
          mintAddress
          name
          old
          originatedAccount
          primaryColor
          properName
          properTicker
          ticker
          tickerAlias
          tickerAliases
          tokenId
          tokenType
          useBip84
        }
      }
    }
  `)

  const nodesAsObject = useMemo(() => {
    if (!data || !data.allAssetsBaseJson || !data.allAssetsBaseJson.nodes) return {}

    return data.allAssetsBaseJson.nodes.reduce((obj, node) => {
      obj[node.name] = node
      return obj
    }, {})
  }, [data])

  return nodesAsObject
}
