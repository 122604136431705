import React from 'react'
import '../../static/components/scss/logos-chrome.scss'

class LogosTrezor extends React.PureComponent {
  render() {
    return (
      <div className="x-assets-supported__row-container">
        <div
          className="x-assets-supported__row x-assets-supported__row--long rellax"
          data-rellax-speed="0.8"
        >
          <a href="/tether-usdt-wallet" title="Tether USD (USDT)">
            <img src="/img/logos/USDT.svg" alt="Tether USD" />
          </a>
          <div className="x-assets-supported__row-break" />
          <a href="/ripple-xrp-wallet" title="XRP (XRP)">
            <img src="/img/logos/XRP.svg" alt="XRP" />
          </a>
          <a href="/bitcoin-cash-wallet-app" title="Bitcoin Cash (BCH)">
            <img src="/img/logos/BCH.svg" alt="Bitcoin Cash" />
          </a>
          <div className="x-assets-supported__row-break" />
          <a href="/decred-wallet-dcr" title="Decred (DCR)">
            <img src="/img/logos/DCR.svg" alt="Decred" />
          </a>
          <a href="/chainlink-wallet" title="ChainLink (LINK)">
            <img src="/img/logos/LINK.svg" alt="ChainLink" />
          </a>
        </div>

        <div
          className="x-assets-supported__row x-assets-supported__row--long rellax"
          data-rellax-speed="-0.8"
        >
          <span className="x-assets-supported__empty" />

          <a href="/litecoin-wallet" title="Litecoin (LTC)">
            <img src="/img/logos/LTC.svg" alt="Litecoin" />
          </a>
          <a href="/bitcoin-wallet" title="Bitcoin (BTC)">
            <img src="/img/logos/BTC.svg" alt="Bitcoin" />
          </a>

          <div className="x-assets-supported__row-break" />
          <a href="/ethereum-wallet" title="Ehereum (ETH)">
            <img src="/img/logos/ETH.svg" alt="Ehereum" />
          </a>

          <a href="/ethereum-classic-wallet-etc" title="Ethereum Classic (ETC)">
            <img src="/img/logos/ETC.svg" alt="Ethereum Classic" />
          </a>
          <span className="x-assets-supported__empty" />
        </div>

        <div
          className="x-assets-supported__row x-assets-supported__row--long rellax"
          data-rellax-speed="0.8"
        >
          <span className="x-assets-supported__empty" />

          <a href="/digibyte-wallet-dgb" title="DigiByte (DGB)">
            <img src="/img/logos/DGB.svg" alt="DigiByte" />
          </a>

          <a href="/zcash-wallet-zec" title="Zcash (ZEC)">
            <img src="/img/logos/ZEC.svg" alt="Zcash" />
          </a>
          <div className="x-assets-supported__row-break" />
          <a href="/dash-wallet" title="Dash (DASH)">
            <img src="/img/logos/DASH.svg" alt="Dash" />
          </a>

          <span className="x-assets-supported__empty" />
        </div>
      </div>
    )
  }
}

export default LogosTrezor
