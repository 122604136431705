/* Load the data */
import { mobileDefaultAvailableAssetNames } from 'js/assets/available/mobile'
import { desktopDefaultAvailableAssetNames } from 'js/assets/available/desktop'
import { createMeta } from '@exodus/trezor-meta'

const fetchAssetsCount = ({ assetsData, platform = null, floorTo5 = true } = {}) => {
  /* find the closest factor 5 */
  const factoring = (num) => Math.floor((num - 1) / 5) * 5
  const { SUPPORTED_ASSETS } = createMeta(assetsData)
  /* return assets supported count for each wallet */
  switch (platform) {
    case 'mobile':
      return floorTo5
        ? factoring(mobileDefaultAvailableAssetNames.size)
        : mobileDefaultAvailableAssetNames.size
    case 'trezor':
      return floorTo5 ? factoring(SUPPORTED_ASSETS.length) : SUPPORTED_ASSETS.length
    case 'desktop':
    default:
      return floorTo5
        ? factoring(desktopDefaultAvailableAssetNames.size)
        : desktopDefaultAvailableAssetNames.size
  }
}

export default fetchAssetsCount
