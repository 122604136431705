// From:
// https://github.com/ExodusMovement/exodus-desktop/blob/master/src/app/_local_modules/assets/available-assets.js
//
// Then you'll find the newly added assets list in:
// https://github.com/ExodusMovement/exodus-desktop/blob/master/src/app/ui/modals/whats-new/whats-new-modal-content-1.react.js

// Conditionally available assets
export const desktopPrivateAssets = [
  // Holesky and Sepolia testnet
  'ethereumholesky',
  'ethereumsepolia',
  // exodusSharesAssetName
  'eden',
  // internalFeaturesAssets
  'bitcoinregtest',
  'bitcointestnet',
  // edenFeaturesAssets
  'fox', // ERC20
  'rarible', // ERC20
  'sportx_matic',
  'test_cardano', // ADA token
  'mean_solana_c5cba5c4',
  'floki_bsc_c4112b36', // BEP20 token
]

// Unavailable assets documented for awareness
export const desktopUnavailableAssets = [
  // old tokens that are not available in the CTR
  'binance',
  'icon',
  'smoothlovepotion_solana',
  'tinyusdc_algorand', // TODO: remove from assets-base? (this is a tinyman liquidity pool token https://algoexplorer.io/asset/552647097)
  // RSK tokens
  'rif_rootstock_c62f668d',
  // XTZ tokens
  'tetherusd_tezos',
]

// Permanently available assets
const availableAssets = [
  'algorand',
  'aptos',
  'ark',
  'aurora',
  'avalanchec',
  'axelar',
  'basemainnet',
  'bcash',
  'bgold',
  'bitcoin',
  'bitcoinsv',
  'bnbmainnet',
  'bsc',
  'cardano',
  'cosmos',
  'cronos',
  'dash',
  'decred',
  'digibyte',
  'dogecoin',
  'elrond',
  'eosio',
  'ethereum',
  'ethereumarbnova',
  'ethereumarbone',
  'ethereumclassic',
  'fantommainnet',
  'filecoin',
  'flare',
  'hedera',
  'iconmainnet',
  'injective',
  'kava',
  'lisk',
  'litecoin',
  'matic',
  'monero',
  'nano',
  'nem',
  'neo',
  'neogas',
  'ontology',
  'ontologygas',
  'optimism',
  'osmosis',
  'polkadot',
  'qtumignition',
  'ravencoin',
  'ripple',
  'rootstock',
  'solana',
  'stellar',
  'tezos',
  'tfuel',
  'theta',
  'tronmainnet',
  'vechainthor',
  'waves',
  'zcash',
  'zilliqa',
]

const availableErc20Tokens = [
  'aave',
  'ach_ethereum_fbad19a6',
  'aeron',
  'agix_ethereum_84616c96',
  'aion',
  'airswap',
  'alchemix',
  'ali_ethereum_9ae8d1eb',
  'amp',
  'ankr',
  'ape',
  'api3',
  'aragon',
  'aragonv2',
  'audius',
  'augur',
  'augurv2',
  'axieinfinity',
  'balancer',
  'bancor',
  'band_ethereum_a9ddc2a2',
  'bat',
  'bit_ethereum_9656e191',
  'blur_ethereum_ef097961',
  'bnktothefuture',
  'bone_ethereum_1cdd7eb5',
  'busd',
  'cbeth_ethereum_32621dca',
  'cdai',
  'ceek_ethereum_63dd5f1b',
  'chainlink',
  'chiliz',
  'civic',
  'compound',
  'coti',
  'cro',
  'crv',
  'cvx',
  'decentraland',
  'dent',
  'dentacoin',
  'digix',
  'dydx',
  'enjincoin',
  'ens',
  'fantom',
  'fet_ethereum_6609900b',
  'forth_ethereum_52c00d9e',
  'fox',
  'frax',
  'frax_share',
  'funfair',
  'gala_ethereum_3e0e1736',
  'geminidollar',
  'gnosis',
  'golem',
  'golemv2',
  'holo',
  'huobi',
  'iexec',
  'illuvium',
  'imx',
  'jasmy_ethereum_2c1e076b',
  'kyberv2',
  'leash_ethereum_562b149d',
  'leo',
  'lidodao',
  'livepeer',
  'lqty_ethereum_126a91b0',
  'looks_ethereum_222f7656',
  'loomv2',
  'loopring',
  'lyxe_ethereum_00e25dfc',
  'maker',
  'mask_ethereum_c2890c79',
  'mcd',
  'mdt_ethereum_1bb644a2',
  'metal',
  'metis_ethereum_54dc1156',
  'mithril',
  'mxc_ethereum_c4923be4',
  'nexo_ethereum_fe4d2476',
  'numeraire',
  'ocean_ethereum_1abd4fcf',
  'ogn_ethereum_fcc7cc39',
  'olympusv2',
  'omisego',
  'oneinch',
  'ousd_ethereum_48fcf72d',
  'paxgold',
  'paxos',
  'pepe_ethereum_22ed551c',
  'perp_ethereum_c75f71b4',
  'pillar',
  'polygon',
  'pol_ethereum_e5c9fadc', // Polygon ecosystem token
  'populous',
  'powerledger',
  'qtum',
  'quant',
  'quantstamp',
  'radar_ethereum_e4d48030',
  'ren',
  'render',
  'request',
  'reth_ethereum_40b16905',
  'revain',
  'revainnew',
  'ripio',
  'rlb_ethereum_a7e53179',
  'rpl_ethereum_2f6fb917',
  'rsr_ethereum_a5eb8f84',
  'pyusd_ethereum_871d3761',
  'salt',
  'sandbox',
  'sdao_ethereum_c59ee345',
  'shibainu',
  'smoothlovepotion',
  'snx',
  'sportx',
  'status',
  'steth',
  'stg_ethereum_08004d24',
  'storj',
  'storm',
  'stormx',
  'sushiswap',
  'syn_ethereum_c7f5e6b8',
  'tel_ethereum_02bc2dbb',
  'tenx',
  'tetherusd',
  'thegraph',
  'trb_ethereum_ad699fe9',
  'trueusd',
  'tru_ethereum_922b250a',
  'uma',
  'uniswap',
  'usdcoin',
  'verasity',
  'viberate',
  'voyager',
  'wax',
  'wbtc',
  'weth',
  'wnxm_ethereum_52ead9a6',
  'woo_ethereum_0b749579',
  'yfi',
  'ygg',
  'zerox',
]

// Algorand
const availableAlgoTokens = [
  // exodusSharesAssetName
  'exit',
  //
  'gems_algorand_60d192b2',
  'opul_algorand_35471831',
  'smile_algorand',
  'stbl_algorand',
  'tetherusd_algorand',
  'usdcoin_algorand',
  'vest_algorand_120e7149',
  'xet_algorand_a17ef051',
  'yieldly_algorand',
]

// Aurora
const availableAuroraTokens = [
  'aurora_aurora_4cb82266',
  // multiline list
]

const availableBasemainnetTokens = [
  'usdc_basemainnet_b5a52617',
  // multiline list
]

// Binance Smart Chain (bsc)
const availableBep20Tokens = [
  'ada_bsc_db5f96ab',
  'avax_bsc_6383077e',
  'eth_bsc_f1d2a904',
  'flux_bsc_2e3e61bf',
  '1inch_bsc_61c44543',
  'alice_bsc_06579e0c',
  'alpaca_bsc_5a5767ef',
  'bnx_bsc_08fb5f48',
  'busd_bsc',
  'brise_bsc_41a56649',
  'bsw_bsc_1af7c444',
  'celr_bsc_af903611',
  'chr_bsc_03abf483',
  'dai_bsc_709f85b7',
  'dodo_bsc_afd0876f',
  'floki_bsc_c4112b36',
  'gal_bsc',
  'gmt_bsc',
  'gst_bsc',
  'high_bsc_1204b987',
  'inj_bsc_47753064',
  'iota_bsc_32b417ef',
  'iotx_bsc_3d250dac',
  'lina_bsc_e410b173',
  'ltc_bsc_2a538e9a',
  'matic_bsc_4cef25c8',
  'mbox_bsc',
  'mdx_bsc_d000e0cd',
  'near_bsc_02be202a',
  'pancakeswap',
  'reef_bsc_d662ee25',
  'shibainu_bsc',
  'sqr_bsc_2fadeb84',
  'tlm_bsc_8df4c3e8',
  'toncoin_bsc_a76181ea',
  'uni_bsc_98044f4e',
  'usdcoin_bsc',
  'usdt_bsc_ddedf0f8',
  'xvs_bsc_62e8348a',
]

// Binance Chain tokens (bnbmainnet)
const availableBinanceTokens = [
  'busd_bnbmainnet',
  'thorchain_bnbmainnet',
  // multiline list
]

// Polygon (Matic) Mainnet
const availablePolygonTokens = [
  'aave_matic_bfdf3c37',
  'blok_matic_79cb78bc',
  'dai_matic_845af41b',
  'ghst_matic_d2b95a36',
  'gns_matic_4ae7a3c1',
  'link_matic_0fc0671d',
  'mvx_matic_60b5e17b',
  'sand_matic_0472de85',
  'stmatic_matic_bac42ee5',
  'usdc_matic_0a883d9b',
  'usdt_matic_86e249c1',
  'wbtc_matic_298d6ace',
  'weth_matic',
]

// Rootstock
const availableRootstockTokens = [
  'dllr_rootstock_6cfc3a7b',
  // multiline list
]

// Solana
const availableSolanaTokens = [
  '7atg_solana_3506aeb0',
  '7dhb_solana_c9e04412',
  '7i5k_solana_c9e04412',
  '8hgy_solana_43b58185',
  'audius_solana',
  'aury_solana_c9e04412',
  'dezx_solana_3b523050',
  'eche_solana_c5cba5c4',
  'gene_solana_c9e04412',
  'jupy_solana_ed7ebe51',
  'kin_solana_c5cba5c4',
  'kinx_solana_bf1442d2',
  'mang_solana_c5cba5c4',
  'mean_solana_c5cba5c4',
  'msol_solana_c9e04412',
  'orca_solana',
  'poli_solana_0cc3745c',
  'raydium',
  'rndr_solana_4b3ba195',
  'serum',
  'staratlas_solana',
  'tetherusd_solana',
  'thegraph_solana',
  'tulip_solana',
  'usdcoin_solana',
  '2b1k_solana_f3924eb0',
]

// Tron
const availableTronTokens = [
  'bittorrentv2',
  'just_tron',
  'sun_tron',
  'tetherusd_tron',
  'usdd_tron',
  'usdcoin_tron',
]

// AvaxC
const availableAvaxCTokens = [
  'joe_avalanchec_872bcd49',
  'gmx_avalanchec_c7b658d1',
  'usdc_avalanchec_185c8bd7',
  'usdte_avalanchec_7d310603',
  'png_avalanchec_4c320d14',
  'qi_avalanchec_19fd2065',
  'savax_avalanchec_4c4408bd',
  'usdt_avalanchec_d80c1afa',
]

// Fantom
const availableFantomTokens = [
  'fusdt_fantommainnet_b94eea88',
  // multiline list
]

// Vechainthor
const availableVechainTokens = [
  'vethor',
  // multiline list
]

// Cardano
const availableCardanoTokens = [
  'snek_cardano_3831f600',
  'worldmobil_cardano_262abe57',
  // multiline list
]

// Arbitrum One
const availableArbitrumOneTokens = [
  'arb_ethereumarbone_aa4791c0',
  'magic_ethereumarbone_57601877',
  'link_ethereumarbone_63f7deae',
  'gmx_ethereumarbone_34725f35',
  'weth_ethereumarbone_c53d6bd0',
  'usdc_ethereumarbone_2e1129c4',
  'usdt_ethereumarbone_efa95268',
  'rdnt_ethereumarbone_58d922a3',
]

// Optimism
const availableOptimismTokens = [
  'dai_optimism_6343ae93',
  'op_optimism_4a7ee59d',
  'usdc_optimism_68bb70cd',
  'usdt_optimism_26487766',
  'wld_optimism_59dbb49e',
]

export const desktopDefaultAvailableAssetNames = new Set([
  ...availableAssets,
  ...availableErc20Tokens,
  ...availableAlgoTokens,
  ...availableAuroraTokens,
  ...availableAvaxCTokens,
  ...availableBasemainnetTokens,
  ...availableBep20Tokens,
  ...availableBinanceTokens,
  ...availableFantomTokens,
  ...availablePolygonTokens,
  ...availableRootstockTokens,
  ...availableSolanaTokens,
  ...availableTronTokens,
  ...availableVechainTokens,
  ...availableCardanoTokens,
  ...availableArbitrumOneTokens,
  ...availableOptimismTokens,
])
